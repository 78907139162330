<template>
  <div class="register-schedule--wrapper">
    <register-container>
      <template slot="header">
        <div :style="{ fontSize: $vuetify.breakpoint.smAndDown ? '0.8em' : '1em' }">
          {{ pageTitle || $t('registration.registerSchedule.title') }}
        </div></template
      >
      <template slot="subtitle">
        <v-scroll-x-transition mode="out-in" v-if="pageSubtitle && pageSubtitle.trim()">
          <div :key="instruction">
            {{ pageSubtitle }}
          </div>
        </v-scroll-x-transition>
      </template>
      <div
        class="timezone-wrapper d-flex justify-center"
        :class="{ 'mt-1 mb-5': $vuetify.breakpoint.smAndDown }"
      >
        <div class="timezone d-flex flex-column align-center justify-center">
          <div>
            <span>{{ $t('main.timezone') }}:</span>
            <span class="font-weight-bold">{{ ' ' }}{{ localUserTimezone }}</span>
          </div>
          <v-dialog v-model="timezoneDialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <div class="text-caption" color="info" v-bind="attrs" v-on="on">
                <a href="#">{{ $t('registration.registerSchedule.wrongTimezone') }}</a>
              </div>
            </template>
            <v-card>
              <v-autocomplete
                :class="this.tenantUrl + '-theme-autocomplete'"
                :items="$store.state.timezones"
                hide-details
                solo
                :value="localUserTimezone"
                @input="setTimezone($event)"
              ></v-autocomplete>
            </v-card>
          </v-dialog>
        </div>
      </div>
      <!--Date Picker -->
      <v-scroll-y-reverse-transition>
        <div
          :class="{
            'date-picker-wrapper': this.$vuetify.breakpoint.mdAndUp,
          }"
          class="d-flex justify-center mt-4 flex-grow-1 flex-column"
          style="
            position: relative;
            border-radius: 8px;
            border: 1px solid;
            border-color: rgba(0, 0, 0, 0.1);
          "
        >
          <div
            class="calendar-loading--wrapper d-flex justify-center align-center"
            v-if="loadingAvailableDays"
          >
            <v-progress-circular indeterminate color="white" size="50" />
          </div>

          <div
            class="d-flex py-md-1 justify-space-between align-center"
            style="background-color: white; border-radius: 8px"
            :style="{ fontSize: $vuetify.breakpoint.smAndDown ? '0.8em' : '1em' }"
            v-if="calendarType === 'month'"
          >
            <v-btn icon @click="$refs.calendar.prev()">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <div class="font-weight-bold">
              {{ $store.getters.toTimezone(selectedDateString).lang(lang).format('MMMM YYYY') }}
            </div>
            <v-btn icon @click="$refs.calendar.next()"><v-icon>mdi-chevron-right</v-icon></v-btn>
          </div>

          <v-calendar
            class="appointment-schedule--wrapper"
            ref="calendar"
            :type="calendarType"
            v-model="selectedDateString"
            @change="getEvents"
            :events="events"
            :interval-minutes="appointmentDuration"
            :interval-count="intervalCount"
            :firstInterval="firstInterval"
            :event-more="false"
            :event-color="eventColor"
            :event-text-color="eventTextColor"
            @click:event="onEventSelect"
            event-overlap-mode="column"
            :categories="['1', '2']"
            @click:date="calendarType = 'month'"
          >
            <template v-slot:day-body>
              <div
                style="height: 100%; width: 100%"
                class="d-flex justify-center align-center"
                v-if="!selectedEmployee && !loadingAvailableDays"
              >
                <div class="d-flex flex-column align-center justify-center pa-2">
                  <div
                    class="empty-icon d-flex justify-center align-center"
                    :style="emptyIconStyle"
                  >
                    <v-icon :large="$vuetify.breakpoint.mdAndUp">mdi-account-alert</v-icon>
                  </div>
                  <v-card-subtitle
                    class="mt-md-4 text-center pa-0 pt-1"
                    :style="{ fontSize: $vuetify.breakpoint.smAndDown ? '0.8em' : '1em' }"
                    >{{ $t('registration.registerSchedule.pleaseSelectProvider') }}</v-card-subtitle
                  >
                </div>
              </div>
            </template>
            <template v-slot:day-header>
              <div v-if="calendarType === 'day' && employees.length > 1">
                <div style="font-size: 0.8em">
                  {{ $t('registration.registerSchedule.selectProvider') }}:
                </div>
                <v-select
                  outlined
                  :items="employees"
                  v-model="selectedEmployee"
                  return-object
                  item-value="id"
                  hide-details
                  dense
                  class="employee-select"
                >
                  <template v-slot:item="{ item }">
                    <div>{{ $t('format.fullName', item) }}</div>
                  </template>
                  <template v-slot:selection="{ item }">
                    <div style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis">
                      {{ $t('format.fullName', item) }}
                    </div>
                  </template>
                </v-select>
              </div>
            </template>
            <template v-slot:event="{ event }">
              <div class="d-flex" style="height: 100%">
                <div
                  class="d-flex flex-column flex-shrink-1"
                  style="min-width: 0; overflow: hidden"
                >
                  <div class="d-flex">
                    <v-icon small :color="eventTextColor" class="mr-1">mdi-clock-outline</v-icon>
                    <div>
                      {{ getEventTime(event) }}
                    </div>
                  </div>
                  <div class="d-flex">
                    <v-icon small :color="eventTextColor" class="mr-1">mdi-account-tie</v-icon>
                    <div>{{ event.name }}</div>
                  </div>
                </div>
                <v-slide-x-transition>
                  <div
                    v-if="isEventSelected(event)"
                    class="d-flex justify-center align-center px-2"
                  >
                    <v-icon color="#48d04c">mdi-check-circle-outline</v-icon>
                  </div>
                </v-slide-x-transition>
              </div>
            </template>
            <template v-slot:day-label="{ date, day }">
              <div
                style="height: 100%; width: 100%"
                :class="{
                  active: dateAvailable(date),
                  inactive: !dateAvailable(date),
                }"
                @click="selectDate(date)"
                class="d-flex justify-center"
              >
                <div style="font-size: 0.8em" class="pt-2">
                  <div>{{ day }}</div>
                  <v-icon v-if="dateSelected(date)" size="24px" color="#48d04c"
                    >mdi-check-circle-outline</v-icon
                  >
                </div>
              </div>
            </template>
            <template v-slot:day="{ start }">
              <div>{{ start }}</div>
            </template>
          </v-calendar>
          <div
            class="d-flex pa-1"
            style="background-color: white; border-radius: 8px"
            v-if="calendarType === 'month'"
          >
            <div
              :style="{ backgroundColor: eventColor }"
              style="width: 27px; height: 27px"
              class="mr-1"
            ></div>
            <div style="font-size: 0.9em">
              {{ $t('registration.registerSchedule.datesAvailable') }}
            </div>
          </div>
        </div>
      </v-scroll-y-reverse-transition>
    </register-container>
  </div>
</template>
<style lang="scss">
.register-schedule--wrapper {
  height: 100%;
  .form-container {
    height: 100% !important;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    .form-content {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
    }
  }
  .empty-icon {
    border: 1px solid #ddd;
    border-radius: 50%;
    background: #eee;
  }
}
.v-event-timed {
  &:hover {
    background-color: rgba(52, 182, 248, 0.5) !important;
  }
  transition: all 0.2s;
}
.calendar-loading--wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.appointment-schedule--wrapper {
  border-width: 0px;
  border-top-width: 1px !important;
  .employee-select {
    .v-select__selections {
      input {
        display: none;
      }
    }
  }
  .v-calendar-weekly__head-weekday {
    background-color: #f7f7f7 !important;
    color: black !important;
  }
  .v-calendar-daily__pane {
    height: 100% !important;
  }
  .v-calendar-daily_head-day-label {
    position: relative;
  }
  .v-calendar-weekly__day-label {
    height: 100%;
    margin-top: unset !important;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
  }
  .active {
    background-color: rgba(52, 182, 248, 0.2);
    &:hover {
      background-color: rgba(52, 182, 248, 0.5);
    }
    transition: all 0.2s;
  }
  .inactive {
    cursor: default !important;
    background-color: white;
  }
}

.loading-days__wrap {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1000;
}
.date-picker-desktop {
  transition: all 1s;
  &:hover {
    z-index: 8 !important;
  }
}
.date-picker-wrapper {
  position: relative;
}
.mobile-date-picker {
  .v-picker__body {
    div {
      .v-date-picker-table {
        height: unset !important;
      }
    }
  }
}
.popup-wrapper {
  position: fixed;
  left: 0;
  top: 100px;
  bottom: 110px !important;
  width: 100%;
  z-index: 10;
}
.popup {
  position: relative;
  margin: auto;
  width: 750px;
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid var(--v-secondary-base);
  border-radius: 8px;
  font-size: 1.2em !important;
}
.popup-button {
  border-radius: 8px !important;
}
.popup-buttons {
  width: 100%;
}
.select-button-wrapper {
  width: 100%;
}
.timezone-wrapper {
  width: 100%;
}
.timezone {
  width: 100%;
}
@media all and (max-width: 768px) {
  .timezone-wrapper {
    font-size: 0.7em;
  }
}
</style>

<script>
/* eslint-disable global-require */
import moment from 'moment';
import to from 'await-to-js';
import * as MOMENT from '../constants/moment';
import RegisterContainer from '../components/RegisterContainer.vue';
import { constructQuery } from '../utils/graphql';
import GET_AVAILABILITY_FOR_DAY from '../graphql/Query/GetAvailabilityForDay.gql';
import { getSpecialEmployeeAppointmentDurations } from '../utils/registration/schedule';

export default {
  name: 'RegisterSchedule',
  components: {
    RegisterContainer,
  },
  data() {
    return {
      allowAppointmentRequest: true,
      appointmentRequestMade: false,
      availableDates: [], // In Local Time
      availableTimes: [], // In Local Time
      /*
      TODO: change to bufferMinutes once
      pageflow 2.0 is fully up and running
      */
      appointmentDuration: 15,

      bufferMinutesV2: null,

      calendarType: 'month',
      config: null,
      currentTime: null,
      dialog: false,
      disabled: false,
      employeeName: this.$store.state.registration.employeeName,
      employees: [],
      eventColor: 'rgba(52, 182, 248, 0.2)',
      eventTextColor: '#00649a',
      initialized: false,
      noAvailability: false,
      loader: false,
      loadingAvailableDays: false,
      otherDays: false,
      MOMENT,
      queueLoader: false,
      selectedDateString: this.$store.getters.toTimezone().format('YYYY-MM-DD'),
      selectedEmployee: null,
      selectedTime: null,
      tenantUrl: this.$store.state.registration.tenantUrl,
      timezoneDialog: false,
      touchStart: 0,
      touchEnd: 0,
      updateInProgress: false,
    };
  },
  created() {
    this.getInitialState();
  },
  async mounted() {
    this.initialize();
    this.initEventListeners();
  },
  beforeDestroy() {
    this.destroyEventListeners();
  },
  computed: {
    route() {
      return this.$route;
    },
    goBack() {
      if (this.calendarType === 'month') return undefined;
      return () => {
        this.calendarType = 'month';
      };
    },
    emptyIconStyle() {
      let dimensions;
      if (this.$vuetify.breakpoint.smAndDown) {
        dimensions = '40px';
      } else {
        dimensions = '100px';
      }
      return { height: dimensions, width: dimensions };
    },
    events() {
      if (this.calendarType === 'month') return [];
      if (this.employees?.length === 1) return this.availableTimes || [];
      return this.availableTimes?.filter((time) => {
        return time.employee?.id === this.selectedEmployee?.id;
      });
    },
    appointmentSkills() {
      return this.$store.state.registration.appointmentSkills;
    },
    firstInterval() {
      if (this.calendarType === 'month') return 0;
      const firstTimeSlot = this.events.length && this.events[0].startTime;
      if (!firstTimeSlot) return 0;
      const startOfDay = this.$store.getters.toTimezone(this.selectedDateString).startOf('day');
      const minutes = this.$store.getters.toTimezone(firstTimeSlot).diff(startOfDay, 'minutes');

      return minutes / this.appointmentDuration - 1;
    },
    intervalCount() {
      if (this.calendarType === 'month') return 0;
      const lastTimeSlot = this.events.length && this.events[this.events.length - 1].startTime;
      if (!lastTimeSlot) return 0;
      const startOfDay = this.$store.getters.toTimezone(this.selectedDateString).startOf('day');
      const minutes = this.$store.getters.toTimezone(lastTimeSlot).diff(startOfDay, 'minutes');

      const firstIntervalMinutes = this.firstInterval * this.appointmentDuration;
      return (
        (minutes - firstIntervalMinutes + this.appointmentDuration + this.appointmentDuration) /
        this.appointmentDuration
      );
    },
    lang() {
      return this.$route.query.lang || 'en';
    },
    makeAppointmentRequest() {
      return !!this.$store.state.registration.appointmentRequest;
    },
    month() {
      return this.$store.getters.toTimezone(this.selectedDateString).format('M');
    },
    availableCalendarDaysDict() {
      return this.availableDates.reduce((dict, date) => {
        // eslint-disable-next-line no-param-reassign
        dict[date.format(MOMENT.VUETIFY_DATE_PICKER_FORMAT)] = true;
        return dict;
      }, {});
    },
    bufferMinutes() {
      if (!this.isPageflowV2) {
        return this.config?.bufferMinutes || null;
      }
      return this.bufferMinutesV2;
    },
    completed() {
      return (
        this.$store.state.registration.appointmentRequest ||
        (this.$store.state.registration.appointmentDate &&
          this.$store.state.registration.appointmentTime)
      );
    },
    currentDate() {
      return this.$store.getters.currentDate;
    },
    employeeId() {
      const { employeeId, restrictShiftsByEmployee } = this.$store.state.registration;
      return restrictShiftsByEmployee ? employeeId : null;
    },
    localUserTimezone() {
      return this.$store.getters.timezone;
    },
    availability() {
      if (this.updateInProgress) return true;

      return !!this.availableDates.length || !!this.availableTimes.length;
    },
    remainingFields() {
      return [
        {
          name: 'appointmentOrApr',
          displayText: this.$t('registration.errors.selectAppointment'),
          valid: this.completed,
        },
      ];
    },
  },
  methods: {
    dateSelected(date) {
      return (
        this.$store.getters
          .toTimezone(this.$store.state.registration.appointmentDate)
          .clone()
          .format('YYYY-MM-DD') === date
      );
    },
    onEventSelect(e) {
      this.selectedTime = e.event.startTime;
      const { start, end } = e.event;
      const duration = this.$store.getters
        .toTimezone(end)
        .diff(this.$store.getters.toTimezone(start), 'minutes');
      this.$store.commit('setRegistration', {
        appointmentRequest: false,
        appointmentTime: this.$store.getters.toTimezone(e.event.startTime),
        appointmentDuration: duration,
        appointmentDate: this.$store.getters.toTimezone(this.selectedDateString),
        employeeId: e.event.employee?.id || null,
        employeeName: e.event.employee ? this.$t('format.fullName', e.event.employee) : null,
      });
      this.$store.commit('setNotification', {
        text: `${this.$t(
          'registration.registerSchedule.appointmentSelected'
        )}: ${this.$store.getters
          .toTimezone(e.event.startTime)
          .format('MMMM Do[,] YYYY')}, ${this.getEventTime(e.event)}`,
        color: '#48d04c',
        showing: true,
        timeout: 8000,
      });
    },
    getEvents(d) {
      this.val = d.start.date;
    },
    getEventTime(event) {
      const { start, end } = event;
      const format = this.lang === 'en' ? 'hh:mm a' : 'HH:mm';
      const startParsed = this.$store.getters.toTimezone(start).format(format);
      const endParsed = this.$store.getters.toTimezone(end).format(format);
      return `${startParsed} - ${endParsed}`;
    },
    // eslint-disable-next-line no-unused-vars
    dateAvailable(date) {
      return this.availableDates.includes(date);
    },
    initEventListeners() {
      document.addEventListener('touchstart', this.onTouchStart);
      document.addEventListener('touchend', this.onTouchEnd);
    },
    isEventSelected(event) {
      return (
        event.startTime === this.selectedTime &&
        event.employee?.id === this.$store.state.registration.employeeId
      );
    },
    destroyEventListeners() {
      document.removeEventListener('touchstart', this.onTouchStart);
      document.removeEventListener('touchend', this.onTouchEnd);
    },
    onTouchStart(e) {
      this.touchStart = e.targetTouches[0].clientX;
    },
    onTouchEnd(e) {
      if (this.calendarType === 'day') return;
      const distance = e.changedTouches[0].clientX - this.touchStart;
      if (distance > 50) {
        this.$refs.calendar.prev();
      } else if (distance < -50) {
        this.$refs.calendar.next();
      }
    },
    async selectDate(date) {
      if (this.availableDates.includes(date)) {
        this.calendarType = 'day';

        this.selectedDateString = date;

        await this.updateAvailabilityForDayHandler(this.$store.getters.toTimezone(date));
      }
    },
    getMonthStartAndEnd() {
      const { timezone } = this.$store.state.user.settings;
      const { selectedDateString } = this;

      const now = this.$store.getters.toTimezone().startOf('day');

      const startTime = moment.tz(selectedDateString, timezone).startOf('month').tz('UTC').unix();
      const endTime = moment.tz(selectedDateString, timezone).endOf('month').tz('UTC').unix();

      const useNow = now.clone().month() + 1 === Number(this.month) && now.unix() < endTime;

      return { startTime: useNow ? now.unix() : startTime, endTime };
    },
    async initialize() {
      if (!this.isPageflowV2) this.getConfig();

      await this.updateAvailableDatesHandler();
      this.initialized = true;
      this.$store.commit('setCompleted', this.completed);
      this.$store.commit('setOnNextClick', () => {
        this.$store.commit('setCompleted', false);
        if (this.isPageflowV2) {
          this.$emit('next');
        } else {
          this.$router.push({ name: this.$store.getters.nextFlowRoute(this.$route.name) });
        }
      });
      this.getLocalTime();
    },
    createAppointmentRequest() {
      this.$store.commit('setRegistration', {
        appointmentRequest: true,
        appointmentTime: null,
        appointmentDuration: this.appointmentDuration,
      });
    },
    getConfig() {
      if (this.$store.state.flowConfig[this.$route.name]) {
        this.config = { ...this.$store.state.flowConfig[this.$route.name] };
      }
    },
    getInitialState() {
      this.selectedDateString = this.$store.state.registration.appointmentDate?.format(
        MOMENT.VUETIFY_DATE_PICKER_FORMAT
      );
      this.selectedTime = this.$store.state.registration.appointmentTime?.clone().unix();
    },
    nextRoute() {
      if (this.isPageflowV2) {
        this.$emit('next');
      } else {
        this.$router.push({ name: this.$store.getters.nextFlowRoute(this.$route.name) });
      }
    },
    getLocalTime() {
      setInterval(() => {
        this.currentTime = moment.tz(this.localUserTimezone).format(MOMENT.TWELVE_HOUR_TIME_FORMAT);
      }, 1000);
    },
    parseTimestamp(timestamp) {
      // Converts timestamps to user's timezone
      return moment.unix(timestamp).utc().tz(this.localUserTimezone);
    },
    isCalendarDayAvailable(dateString) {
      return this.availableCalendarDaysDict[dateString];
    },

    setTimezone(newTimezone) {
      this.$store.commit('saveSettings', { timezone: newTimezone });
      this.timezoneDialog = false;
    },
    async updateAvailableDates(employeeId) {
      this.loadingAvailableDays = true;
      const { startTime, endTime } = this.getMonthStartAndEnd();
      const variables = {
        providerId: this.$store.getters.getProviderId(),
        employeeId,
        startTime,
        endTime,
        timezone: this.localUserTimezone,
        skills: this.appointmentSkills?.length ? this.appointmentSkills : null,
      };

      const [errors, query] = await to(
        this.$apollo.query({
          query: await this.$store.getters.getQuery('GetAvailableDays'),
          variables,
          fetchPolicy: 'no-cache',
        })
      );
      const { getAvailableDays: availableDays = [] } = query.data;
      this.loadingAvailableDays = false;
      if (errors) {
        return [];
      }
      return availableDays.reduce((acc, timestamp) => {
        const time = this.parseTimestamp(timestamp);
        const timeString = time.clone().format('YYYY-MM-DD');
        if (!this.bufferMinutes) {
          return [...acc, timeString];
        }
        if (
          time.clone().unix() > moment(this.currentDate).add(this.bufferMinutes, 'minutes').unix()
        ) {
          return [...acc, timeString];
        }
        return [...acc];
      }, []);
    },
    async updateAvailableDatesHandler() {
      const { availableEmployees } = this.$store.state.registration;
      if (availableEmployees.length) {
        this.availableDates = await availableEmployees.reduce(async (all, current) => {
          const previousTimes = await all;
          const times = await this.updateAvailableDates(current);

          return [...previousTimes, ...times];
        }, Promise.resolve([]));
      } else {
        this.availableDates = await this.updateAvailableDates();
      }
    },
    constructAvailabilityForDayQuery() {
      const { id } = this.$route.params;
      const specialEmployeesAndDurations = getSpecialEmployeeAppointmentDurations(id);

      if (specialEmployeesAndDurations) {
        const variables = {};
        Object.entries(specialEmployeesAndDurations).forEach(([key, value]) => {
          variables[`employeeIdGetAvailabilityForDay${key}`] = key;
          variables[`durationInMinutesGetAvailabilityForDay${key}`] = value;
        });
        variables.employeeIdGetAvailabilityForDayAll = null;
        variables.durationInMinutesGetAvailabilityForDayAll = this.appointmentDuration;

        return {
          query: constructQuery(
            [
              ...Object.entries(specialEmployeesAndDurations).map(([key]) => ({
                query: GET_AVAILABILITY_FOR_DAY,
                name: `getAvailabilityForDay${key}`,
              })),
              {
                query: GET_AVAILABILITY_FOR_DAY,
                name: 'getAvailabilityForDayAll',
              },
            ],

            'getAvailabilityForDay',
            ['employeeId', 'durationInMinutes']
          ),
          variables,
        };
      }
      return {
        query: GET_AVAILABILITY_FOR_DAY,
        variables: {
          employeeId: null,
          durationInMinutes: this.appointmentDuration,
        },
      };
    },
    organizeTimeslots(slots) {
      const { getAvailabilityForDayAll: allSlots } = slots;
      const { availability } = slots;
      if (allSlots) {
        const specialKeys = Object.keys(slots)
          .filter((key) => key !== 'getAvailabilityForDayAll')
          .map((key) => key.split('getAvailabilityForDay')[1]);

        const specialSlots = Object.keys(slots).reduce((all, current) => {
          if (current === 'getAvailabilityForDayAll') return all;
          return [...all, ...slots[current]];
        }, []);
        const filteredAllSlots = allSlots.filter((slot) => {
          const { employee } = slot;
          const { id } = employee || {};
          return !specialKeys.includes(id);
        });
        const filtered = [...specialSlots, ...filteredAllSlots];

        return filtered;
      }
      return availability;
    },
    async updateAvailabilityForDay(localDate) {
      const { availableEmployees } = this.$store.state.registration;
      let constructedQuery;
      let variables;
      if (availableEmployees.length) {
        constructedQuery = GET_AVAILABILITY_FOR_DAY;
        variables = {
          employeeId: availableEmployees[0],
          durationInMinutes: this.appointmentDuration,
        };
      } else {
        const { query: q, variables: v } = this.constructAvailabilityForDayQuery();
        constructedQuery = q;
        variables = v;
      }

      this.loadingAvailableDays = true;
      const dateTimestamp = localDate.utc().unix();
      const [errors, query] = await to(
        this.$apollo.query({
          query: constructedQuery,
          variables: {
            providerId: this.$store.getters.getProviderId(),
            date: dateTimestamp, // Date float in unix time
            timezone: this.localUserTimezone,
            includeHold: false,
            skills: this.appointmentSkills?.length ? this.appointmentSkills : null,
            ...variables,
          },
          fetchPolicy: 'no-cache',
        })
      );
      const a = this.organizeTimeslots(query.data);
      this.loadingAvailableDays = false;
      if (errors || !a) {
        this.$store.commit('setNotification', {
          color: 'error',
          text: errors || this.$t('registration.errors.noAvailabilityForDay'),
          timeout: 3000,
        });
        return [];
      }
      const { id } = this.$route.params;
      const durations = getSpecialEmployeeAppointmentDurations(id);
      return a.reduce((all, time) => {
        if (!time.employee) return all;
        if (!this.employees.some((e) => e.id === time.employee?.id))
          this.employees.push(time.employee);
        const start = this.$store.getters
          .toTimezone(time.startTime)
          .clone()
          .format('YYYY-MM-DD HH:mm');
        const duration = durations
          ? durations[time.employee.id] ?? this.appointmentDuration
          : this.appointmentDuration;
        const end = this.$store.getters
          .toTimezone(time.startTime)
          .add(duration, 'minutes')
          .format('YYYY-MM-DD HH:mm');

        return [
          ...all,
          {
            ...time,
            start,
            end,
            name: `${this.$t('format.fullName', time.employee)}`,
          },
        ];
      }, []);
    },
    async updateAvailabilityForDayHandler(date) {
      this.employees = [];
      this.availableTimes = await this.updateAvailabilityForDay(date);
      this.selectedEmployee = this.availableTimes[0]?.employee;
    },
    async updateAvailability() {
      if (this.updateInProgress) return;
      const selectedDate = this.$store.getters.toTimezone(this.selectedDateString);
      await this.updateAvailableDatesHandler();
      await this.updateAvailabilityForDayHandler(selectedDate);
    },
  },
  watch: {
    availability(val) {
      this.noAvailability = !val;
    },
    localUserTimezone: {
      handler() {
        this.updateAvailability();
      },
    },
    month() {
      this.updateAvailableDatesHandler();
    },
    completed(val) {
      this.$store.commit('setCompleted', val);
    },
  },
};
</script>
