/* eslint-disable import/prefer-default-export */
import { APPOINTMENT_DURATIONS } from '../../constants/pageflows/config/cannabis';
import { NODE_ENV } from '../../constants/env';

export const getSpecialEmployeeAppointmentDurations = (pageflowId) => {
  try {
    return APPOINTMENT_DURATIONS[pageflowId][NODE_ENV];
  } catch {
    return null;
  }
};
