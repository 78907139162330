/* eslint-disable import/prefer-default-export */
const CANNABIS_DURATIONS = {
  development: {
    VBaS9qnn55gj3FvHi3eVfI8M9kg1: 15,
  },
  qa: {
    CGXpPUb78Wa0BHPA1JSPI9YJ8v42: 15,
  },
  prod: {
    FhF6FytE0pMXty46uTRM39RJTNR2: 15,
  },
};

export const APPOINTMENT_DURATIONS = {
  cannabis: CANNABIS_DURATIONS,
  mendo: CANNABIS_DURATIONS,
  aurora: CANNABIS_DURATIONS,
  hybridpharm: CANNABIS_DURATIONS,
  vivo: CANNABIS_DURATIONS,
  default: 10,
};
